<!--
 * @Author: 蒋威
 * @Date: 2022-05-23 16:52:25
 * @LastEditors: 蒋威
 * @LastEditTime: 2022-06-07 19:40:34
 * @Description: 面包屑导航
-->
<template>
  <div class="breadcrumb-container">
    <div class="response breadcrumb-wrap">
      <span class="current">当前位置:</span>
      <!-- <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item><a href="/">活动管理</a></el-breadcrumb-item>
        <el-breadcrumb-item>活动列表</el-breadcrumb-item>
        <el-breadcrumb-item>活动详情</el-breadcrumb-item>
      </el-breadcrumb> -->
      <ul>
        <template v-for="(item, index) in menu">
          <li :key="index">
            <span
              :class="index == menu.length - 1 ? 'nomarl' : ''"
              @click="jump(item, index)"
            >
              {{ item.title }}
            </span>
            <template v-if="index != menu.length - 1">/</template>
          </li>
        </template>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  inject: ['reload'],
  props: {
    firstMenu: {
      type: Object,
    },
    currentMenu: {
      type: Object,
    },
    dcrumbName: {
      type: String | Number,
    },
  },
  data() {
    return { menu: [] }
  },
  methods: {
    jump(item, index) {
      let query
      if (name == 'home') {
        query = {}
      } else {
        // 一级菜单
        if (index == 1) {
          query = {
            moduleId: localStorage.getItem('moduleId'),
          }
        }
        // 二级菜单 并且不是最后数组一项
        if (index == 2 && index < this.menu.length - 1) {
          query = {
            cate_id: item.id,
            moduleId: localStorage.getItem('moduleId'),
          }
        }
        // 最后数组一项 直接刷新页面
        if (index == this.menu.length - 1) {
          location.reload()
          return
        }
      }
      const name = this.getRouterName(item.model)
      this.reload()
      this.$router.push({
        name: name,
        query: query,
      })
    },
    setMenu() {
      let menu = []
      // 首页
      menu.push({ title: '首页', model: 'home' })
      // 如果存在选中的一级菜单 已排除首页
      if (this.firstMenu) {
        menu.push(this.firstMenu)
        if (this.currentMenu && this.firstMenu.id != this.currentMenu.id) {
          menu.push(this.currentMenu)
        }
      }
      // 最后一级名称
      menu.push({ title: localStorage.getItem('dcrumbName') })
      this.menu = menu
    },

    // 根据模型匹配路由名称
    getRouterName(model) {
      let name = 'home'
      switch (model) {
        case 'home':
          name = 'home'
          break
        case 'news':
          name = 'news'
          break
        // 产品案例
        case 'other':
          name = 'news'
          break
        case 'url':
          name = 'url'
          break
        default:
          name = 'home'
          break
      }
      return name
    },
  },
  mounted() {
    // this.setMenu()
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/fontsize.scss';
.breadcrumb-container {
  background-color: #f5f5f5;
  .breadcrumb-wrap {
    height: 60px;
    display: flex;
    align-items: center;
    span {
      height: 21px;
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      line-height: 21px;
      color: #666;
      opacity: 1;
    }
    .current {
      margin-right: 5px;
    }
    ul {
      display: flex;
      height: 21px;
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      line-height: 21px;
      color: #666;
      opacity: 1;
      li {
        margin: 0 4px;
        span {
          cursor: pointer;
          &:hover {
            color: #39957a;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 940px) {
  .breadcrumb-container {
    background-color: #f5f5f5;
    .breadcrumb-wrap {
      height: unset;
      padding: $fontSize_4 0;
      display: flex;
      align-items: center;
      span {
        height: $fontSize_14;
        font-size: $fontSize_12;
        font-family: Microsoft YaHei;
        font-weight: 400;
        line-height: $fontSize_14;
      }
      .current {
        margin-right: 5px;
        font-size: $fontSize_12;
        width: 5*$fontSize_14;
      }
      ul {
        display: block;
        height: unset;
        font-size: $fontSize_12;
        font-family: Microsoft YaHei;
        font-weight: 400;
        line-height: $fontSize_14;
        color: #666;
        opacity: 1;
        li {
          display: inline-block;
          margin: 0 4px;
          span {
            cursor: pointer;
            &:hover {
              color: #39957a;
            }
          }
        }
      }
    }
  }
}
</style>