<!--
 * @Author: 蒋威
 * @Date: 2022-05-06 14:54:58
 * @LastEditors: 蒋威
 * @LastEditTime: 2022-08-08 10:37:41
 * @Description: 
-->
<template>
  <div class="layout-headerBox">
    <div class="response">
      <header class="layout-header">
        <!-- logo -->
        <div class="logo-wrap">
          <img :src="website.logoId" alt="" />
          <!-- <img src="@/assets/img/logo.png" alt=""> -->
        </div>
        <!-- 一级菜单 -->
        <nav id="menuBox" :class="rightNavVisible ? 'right-nav' : ''">
          <div class="first-menu-wrap">
            <ul
              class="first-menu"
              :class="menuList.length > 8 ? 'lineFeed' : ''"
            >
              <li
                class="first-menu-item pointer"
                :class="
                  $route.name == 'home' && item.model == 'home'
                    ? 'active'
                    : $route.name != 'home' && $route.query.moduleId == item.id
                    ? 'active'
                    : $route.name == 'newsInfo' && index == 0
                    ? 'active'
                    : ''
                "
                v-for="(item, index) in menuList"
                :key="index"
                @click="changeFirstMenu(item, index)"
              >
                <!-- v-for="(item, index) in [...menuList,...menuList]" -->
                {{ item.title }}
              </li>
            </ul>
          </div>
        </nav>
        <!-- 微信 电话 -->
        <div class="contact-wrap">
          <div class="icon-box pointer weChatBox">
            <i class="iconfont icon-weixin"></i>
            <div class="weChatimg-box weChatimg">
              <img :src="website.qrCodeId" alt="" />
            </div>
          </div>
          <div class="icon-box pointer weChatBox">
            <i class="iconfont icon-dianhuatianchong"></i>
            <div class="weChatimg-box telephone-box">
              <div>
                <span class="Hotline">服务热线:</span>
                <span class="Hotline-phone">{{ website.phone }}</span>
              </div>
            </div>
          </div>
        </div>

        <div class="header-icon-wrap">
          <i
            class="iconfont icon-caidan"
            @click.prevent.stop="switchNavState"
          ></i>
        </div>
      </header>
    </div>
    <div class="border-1"></div>
  </div>
</template>

<script>
import { channelTree } from '@/api/menu/index.js'
import { website_list } from '@/api/homePage/index.js'
export default {
  name: 'LayoutHeader',
  inject: ['reload'],
  computed: {},
  props: {
    // 网站信息
    website: {
      type: Object,
    },
  },
  data() {
    return {
      menuList: [],
      // 右侧菜单  页面宽度不够时，配合媒体查询显示
      rightNavVisible: false,
    }
  },
  methods: {
    closeMenu() {
      this.rightNavVisible = false
    },
    switchNavState() {
      this.rightNavVisible = !this.rightNavVisible
    },
    /**
     * @Auth: 蒋威
     * @Date: 2022-05-08 14:44:12
     * @Description: 获取路由名称
     * @param {*} model
     */
    getRouterName(model) {
      let name = 'home'
      switch (model) {
        case 'home':
          name = 'home'
          break
        case 'news':
          name = 'news'
          break
        // 产品案例
        case 'other':
          name = 'news'
          break
        case 'url':
          name = 'url'
          break
        default:
          name = 'home'
          break
      }
      return name
    },
    changeFirstMenu(item, index) {
      // 缓存选中的一级栏目id
      localStorage.setItem('moduleId', item.id)
      // 如果是外链，则跳转到外链地址
      if (item.model == 'url' && item.url) {
        window.open(item.url, '_blank')
        return
      }
      let query
      // 首页不需要路由参数
      if (item.model == 'home') {
        query = {}
      } else {
        query = {
          moduleId: item.id,
        }
      }
      console.log('6666787', this.$route)
      // cate_id
      this.reload()
      this.$router.push({
        name: this.getRouterName(item.model),
        query: query,
      })
    },
    async getMenuList() {
      const res = await channelTree({ diff: 'web' })

      if (res.code === this.$httpCode) {
        this.menuList = res.data
        const moduleId = this.$route.query.moduleId
        for (let index = 0; index < this.menuList.length; index++) {
          if (this.menuList[index].id == moduleId - 0) {
            // 缓存选中的一级栏目id
            localStorage.setItem('moduleId', this.menuList[index].id)
          }
        }
      } else {
        this.$emit('setType', '404')
      }
      // 如果菜单数据为空
      if (this.menuList.length < 1) {
        this.$emit('setType', '404')
      }
    },
  },
  created() {
    this.getMenuList()
  },
  mounted() {},

  watch: {
    rightNavVisible(value) {
      if (value) {
        document.body.addEventListener('click', this.closeMenu)
      } else {
        document.body.removeEventListener('click', this.closeMenu)
      }
    },
  },
}
</script>
<style lang="scss" scoped>
@import '@/assets/css/fontsize.scss';
.layout-headerBox {
  position: fixed;
  height: 80px;
  top: 0;
  width: 100%;
  z-index: 12;
  background: #fff;
}
header {
  display: flex;
  width: 100%;
  .logo-wrap {
    width: 200px;
    flex: 0 0 200px;
    height: 80px;
    img {
      width: 200px;
      height: 80px;
    }
    // border-bottom-right-radius: 40px;
    // background: linear-gradient(152deg, #6ecee2 0%, #55d0b0 55%, #45d18f 100%);
  }
  nav {
    // flex: 0 0 940px;
    flex: 1;
    // 一级菜单
    .first-menu-wrap {
      background: #fff;
      height: 80px;
      width: 100%;
      font-size: 20px;
    }
    .first-menu {
      display: flex;
      height: 80px;
      align-items: center;
      justify-content: space-around;
      margin: 0 20px;
      .first-menu-item {
        height: 40px;
        line-height: 40px;
        align-items: center;
        justify-content: center;
        color: #000;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        -webkit-box-orient: vertical;
        text-align: center;
        font-size: 20px;
        &:hover {
          color: #39957a;
        }
        &.active {
          color: #39957a;
        }
      }
      &.lineFeed {
        align-items: unset;
        flex-wrap: wrap;
        justify-content: flex-start;
        .first-menu-item {
          height: 40px;
          line-height: 40px;
          margin-right: 14px;
          &:last-child {
            margin-right: 0;
          }
          &:nth-child(8n) {
            margin-right: 0;
          }
          // margin-right: 40px;
        }
      }
    }
  }
  .contact-wrap {
    margin-top: 20px;
    display: flex;
    flex: 0 0 160px;
    .icon-box {
      position: relative;
      width: 40px;
      height: 40px;
      text-align: center;
      line-height: 40px;
      border: 1px solid #dedede;
      border-radius: 50%;
      opacity: 1;
      margin-left: 38px;
      transition: all 0.6s ease 0s;
      i {
        font-size: 22px;
        transition: all 0.6s ease 0s;
      }
      .weChatimg-box {
        position: absolute;
        visibility: hidden;
        z-index: 9;
        top: 50px;
        left: -55px;
        transition: all 0.6s;
        box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.16);
        img {
          width: 150px;
          height: 150px;
        }
        &.weChatimg {
          height: 150px;
          border: 1px solid #eeeeee;
          background: rgba(255, 255, 255, 1);
        }
        &:after {
          display: none;
          position: absolute;
          // display: inline-block;
          top: 0;
          right: 70px;
          width: 0;
          height: 0rem;
          content: '';
          border-style: solid;
          border-width: 8px;
          border-color: #f5f5f5 #f5f5f5 transparent transparent;
          box-shadow: 2px 2px 2px #ccc;
          transform: rotate(315deg);
          z-index: -1;
        }
      }
      .telephone-box {
        left: -80px;
        padding-top: 0px;
        // background-color: #e4e7ed;
        &:after {
          right: 94px;
          border-color: #e4e7ed #e4e7ed transparent transparent;
        }
        div {
          width: 240px;
          background: rgba(255, 255, 255, 1);
          border: 1px solid #eeeeee;
          box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.16);
          .Hotline {
            color: #39957a;
          }
        }
      }
      &:hover {
        border: 1px solid #39957a;
        i {
          font-size: 28px;
          color: #39957a;
        }
      }
    }

    .weChatBox:hover {
      .weChatimg-box,
      .weChatimg-box:hover {
        visibility: visible;
      }
    }
  }
  .header-icon-wrap {
    display: none;
  }
}
// .layout-headerBox{
//   min-width: 1300px;
//   // overflow: hidden;
// }
.border-1 {
  border-bottom: 1px solid #ececec;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .layout-headerBox {
    header {
      .contact-wrap {
        .icon-box {
          .telephone-box {
            div {
              width: 150px;
              line-height: 30px;
              .Hotline {
                color: #39957a;
              }
              .Hotline-phone {
                margin: 0;
                display: block;
                line-height: 24px;
                padding-bottom: 6px;
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 1279px) and (max-width: 1366px) {
  .layout-headerBox {
    header {
      .contact-wrap {
        .icon-box {
          .telephone-box {
            left: unset;
            right: -0px;
            div {
              width: 150px;
              line-height: 30px;
              .Hotline {
                color: #39957a;
              }
              .Hotline-phone {
                margin: 0;
                display: block;
                line-height: 24px;
                padding-bottom: 6px;
              }
            }
          }
        }
      }
    }
  }
}
@media only screen and (min-width: 941px) and (max-width: 1279px) {
  .layout-headerBox {
    header {
      .contact-wrap {
        .icon-box {
          .telephone-box {
            left: unset;
            right: -10px;
            div {
              width: 150px;
              line-height: 30px;
              .Hotline {
                color: #39957a;
              }
              .Hotline-phone {
                margin: 0;
                display: block;
                line-height: 24px;
                padding-bottom: 6px;
              }
            }
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 940px) {
  .layout-headerBox {
    position: fixed;
    height: 72px;
    top: 0;
    width: 100%;
    min-width: unset;
    z-index: 12;
    background: #fff;
  }
  header {
    display: flex;
    padding: 0 0px;
    position: relative;
    justify-content: space-between;
    width: 100%;
    .logo-wrap {
      width: 180px;
      flex: 0 0 180px;
      height: 72px;
      img {
        width: 180px;
        height: 72px;
      }
    }
    nav {
      display: none;
      position: absolute;
      top: 72px;
      right: -2%;
      height: unset;
      background-color: #fff;
      border-radius: 4px;
      box-shadow: 0 8px 24px rgb(81 87 103 / 16%);
      &.right-nav {
        display: block;
      }
      // 一级菜单
      .first-menu-wrap {
        height: unset;
      }
      .first-menu {
        display: block;
        height: auto;
        padding: 14px 0;
        .first-menu-item {
          background-color: #fff;
          margin-bottom: 10px;
          display: block;
          width: usnet;
          max-width: usnet;
          min-width: usnet;
          height: 20px;
          font-size: 16px;
          line-height: 24px;
          &:hover,
          &.active {
            width: usnet;
            max-width: none;
            min-width: 0;
          }
        }
        &.lineFeed {
        }
      }
    }
    .contact-wrap {
      display: none;
    }
    .header-icon-wrap {
      display: flex;
      align-items: center;
      i {
        font-size: 32px;
      }
    }
  }
  .border-1 {
    display: none;
  }
}
</style>
