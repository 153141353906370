/*
 * @Author: 蒋威
 * @Date: 2022-04-08 15:15:56
 * @LastEditors: 蒋威
 * @LastEditTime: 2022-08-09 09:51:51
 * @Description:
 */
/**
 * @Auth: 蒋威
 * @Date: 2022-04-08 15:17:26
 * @Description: 千分位分割
 * @param {*} str
 */
// export const split3str = (str) => {

export const split3str = (stra) => {
  let str = stra ? stra + '' : stra
  if (str == null && str.length == 0) return null
  var newStr = []
  var count = 0
  var strDot = null // 字符串小数部分
  if (str.indexOf('.') != -1) {
    strDot = str.substring(str.indexOf('.'), str.length)
    str = str.substring(0, str.indexOf('.'))
  }
  for (var i = str.length - 1; i >= 0; i--) {
    if (count % 3 == 0 && count != 0) {
      newStr.unshift(',')
      newStr.unshift(str.charAt(i))
    } else {
      newStr.unshift(str.charAt(i))
    }
    count++
  }
  return strDot ? newStr.join('') + strDot : newStr.join('')
}
// 时间转换成年-月-日 params
export const formateDateYMD = (date, params = {}) => {
  // showYMD  显示年月日
  let options = { division: '-', monthZero: '0', dateZero: '0', isYMD: false }
  options = { ...options, ...params }
  if (date == null || date == undefined || date.length < 1) {
    return ''
  }
  function addDateZero(num, zero) {
    return num < 10 ? '0' + num : num
  }
  const d = new Date(date)
  const formatdatetime =
    d.getFullYear() +
    (options.isYMD ? '年' : options.division) +
    addDateZero(d.getMonth() + 1, options.monthZero) +
    (options.isYMD ? '月' : options.division) +
    addDateZero(d.getDate(), options.dateZero) +
    (options.isYMD ? '日' : '')
  return formatdatetime
}
// 获取对应的模板 格式x-x-x
export const getModelType = (obj) => {
  let modelType = ''
  if (obj.columnModel) {
    modelType += obj.columnModel
  }
  // 没有二级模型
  if (obj.columnModel == 3) {
    return modelType
  }
  // 二级模型
  if (obj.columnClass) {
    modelType += '-' + obj.columnClass
  }
  // 三级模型
  if (obj.columnModel == 4) {
    if (obj.otherChoice) {
      modelType += '-' + obj.otherChoice
    }
  }
  if (obj.columnModel == 1 && obj.columnClass == 1 && obj.showModel) {
    modelType += '-' + obj.showModel
  }
  return modelType
  // return obj.columnModel + '-' + obj.columnClass + '-' + obj.otherChoice
}

export function IEVersion() {
  var userAgent = navigator.userAgent //取得浏览器的userAgent字符串
  var isIE =
    userAgent.indexOf('compatible') > -1 && userAgent.indexOf('MSIE') > -1 //判断是否IE<11浏览器
  var isEdge = userAgent.indexOf('Edge') > -1 && !isIE //判断是否IE的Edge浏览器
  var isIE11 =
    userAgent.indexOf('Trident') > -1 && userAgent.indexOf('rv:11.0') > -1
  if (isIE) {
    var reIE = new RegExp('MSIE (\\d+\\.\\d+);')
    reIE.test(userAgent)
    var fIEVersion = parseFloat(RegExp['$1'])
    if (fIEVersion == 7) {
      return 7
    } else if (fIEVersion == 8) {
      return 8
    } else if (fIEVersion == 9) {
      return 9
    } else if (fIEVersion == 10) {
      return 10
    } else {
      return 6 //IE版本<=7
    }
  } else if (isEdge) {
    return 'edge' //edge
  } else if (isIE11) {
    return 11 //IE11
  } else {
    return -1 //不是ie浏览器
  }
}
// 获取时间
export function getDate(date, format = 'M') {
  if (!date) {
    return ''
  }
  switch (format) {
    case 'y':
    case 'Y': {
      return date.substring(0, 4)
      break
    }
    case 'm':
    case 'M': {
      return date.substring(5, 7)
      break
    }
    case 'd':
    case 'D': {
      return date.substring(8, 10)
      break
    }
    case 'md':
    case 'MD': {
      return date.substring(5, 10)
      break
    }
    case 'ymd':
    case 'YMD': {
      return date.substring(0, 10)
      break
    }
    default: {
      return date.substring(5, 10)
      break
    }
  }
}

// 获取html 内容
export function getHtml(data = '') {
  if (!!data) {
    let html = data.replace(/<img[^>]*>/gi, function (match, capture) {
      // 图片宽高度处理
      var match = match.replace(
        /(style="(.*?)")|(width="(.*?)")|(height="(.*?)")/gi,
        ''
      )
      // 图片宽度设置
      match = match.replace(
        /\<img/gi,
        '<img style="max-width:100%;height:auto overflow: hidden;"'
      )
      return match
    })
    // let html = data.replace(
    //   /\<img style=\"/gi,
    //   '<img style="max-width:100%;height:auto;overflow: hidden;'
    // )
    // html =  html.replace(
    //   /\<img src=/gi,
    //   '<img style="max-width:100%;height:auto;overflow: hidden;" src='
    // )
    // html = html.replace(/\<p style="/gi, '<p style="word-break: break-all ')
    // html = html.replace(/\<p/gi, '<p style="word-break: break-all;"')
    return html
  } else {
    return ''
  }
}
