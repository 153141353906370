/*
 * @Author: 蒋威
 * @Date: 2022-04-14 16:51:36
 * @LastEditors: 蒋威
 * @LastEditTime: 2022-04-19 13:56:35
 * @Description:
 */
/**
 * @description 判读是否为外链
 * @param path
 * @returns {boolean}
 */
export function isExternal(path) {
  return /^(https?:|mailto:|tel:|\/\/)/.test(path)
}

/**
 * @description 判断是否是数组
 * @param arg
 */
export function isArray(arg) {
  if (typeof Array.isArray === 'undefined') {
    return Object.prototype.toString.call(arg) === '[object Array]'
  }
  return Array.isArray(arg)
}

//判断字符是否为空
export function isEmpty(obj) {
  return typeof obj === 'undefined' || obj === null || obj === ''
}
