/*
 * @Author: 蒋威
 * @Date: 2022-04-06 08:57:29
 * @LastEditors: 蒋威
 * @LastEditTime: 2022-05-23 08:44:03
 * @Description:
 */
/**
 * @description 导出网络配置
 **/
module.exports = {
  // 默认的接口地址，开发环境和生产环境都会走/vab-mock-server
  // 正式项目可以选择自己配置成需要的接口地址，如"https://api.xxx.com"
  // 问号后边代表开发环境，冒号后边代表生产环境
  // baseURL:
  //   // process.env.NODE_ENV === 'development'
  //   //   ? '/api'
  //   //   : 'http://172.18.5.13:8080/api',
  //   process.env.VUE_APP_BASE_API,
  // 配后端数据的接收方式application/json;charset=UTF-8 或 application/x-www-form-urlencoded;charset=UTF-8
  contentType: 'application/json;charset=UTF-8',
  // 最长请求时间
  requestTimeout: 30000,
  // 操作正常code，支持String、Array、int多种类型
  successCode: [200, 0, '200', '0'],
  // 数据状态的字段名称
  statusName: 'code',
  // 状态信息的字段名称
  messageName: 'msg',
  // 消息框消失时间
  messageDuration: 3000,
  // 所有模型类型
  //1 （新闻模型-单个分类），
  // 1-1 新闻-单个分类-公益故事
  // 1-1-1 新闻-单个分类-公益活动
  // 1-1-2 新闻-单个分类-公益故事
  // 1-1-3 新闻-单个分类-公益视频
  // 1-2（新闻模型-单个内容），
  // 1-2-1（新闻模型-单个内容-联系我们），
  // 2-1（项目模型-单个分类），
  // 2 2-2（项目模型单个内容），
  // 3（外部链接），
  // 4（其他模型-信息公开-善款收支），
  // 4-1（其他模型-信息公开-善款收支），
  // 4-1-1（其他模型-信息公开-善款收支），
  // 4-1-2（其他模型-信息公开-收支报告），
  // 4-2（其他模型--合作医院），
  // 4-2-1（其他模型-合作伙伴-合作医院），
  // 4-2-2（其他模型-合作伙伴-合作伙伴
  modelList: [
    '1',
    '1-1',
    '1-1-1',
    '1-1-2',
    '1-1-3',
    '1-2',
    '1-2-1',
    '2',
    '2-1',
    '2-2',
    '3',
    '4',
    '4-1',
    '4-1-1',
    '4-1-2',
    '4-2',
    '4-2-1',
    '4-2-2',
  ],
}
