/*
 * @Author: 蒋威
 * @Date: 2022-04-14 16:26:02
 * @LastEditors: 蒋威
 * @LastEditTime: 2022-05-26 15:24:27
 * @Description:
 */
// 首页模块接口
import request from '@/utils/request'
// 查询网站配置
export function website_list(params) {
  return request({
    url: `/api/web/website_list`,
    method: 'get',
    params,
  })
}

// 首页新闻
export function homeNews(id) {
  return request({
    url: `/api/web/homeNews/${id}`,
    method: 'get',
  })
}

// 首合作伙伴
export function cooperator_list(params) {
  return request({
    url: `/api/web/cooperator_list`,
    method: 'get',
    params,
  })
}
// 首页项目
export function homeProject(params) {
  return request({
    url: `/api/web/homeProject`,
    method: 'get',
    params,
  })
}

// 首页项目
export function homeActivitys(params) {
  return request({
    url: `/api/web/homeActivitys`,
    method: 'get',
    params,
  })
}
// 首页广告图片
export function websiteAds_list(params) {
  return request({
    url: `/api/web/websiteAds_list`,
    method: 'get',
    params,
  })
}
// 首页排行榜
export function webrank(params) {
  return request({
    url: `/api/web/rank`,
    method: 'get',
    params,
  })
}
