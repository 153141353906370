<!--
 * @Author: 蒋威
 * @Date: 2022-04-25 09:17:38
 * @LastEditors: 蒋威
 * @LastEditTime: 2022-05-11 09:11:15
 * @Description: 
-->
<!--
 * @Author: 蒋威
 * @Date: 2022-04-21 11:15:48
 * @LastEditors: 蒋威
 * @LastEditTime: 2022-04-21 17:17:18
 * @Description: 
-->
<template>
  <div class="wscn-http404-container response">
    <div class="wscn-http404">
      <img
        class="pic-404__parent"
        src="@/assets/404_images/404.png"
        alt="404"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'Page404',
}
</script>

<style lang="scss" scoped>
.wscn-http404-container {
  height: 100vh;
  display: flex;
  width: 1014px;
  align-items: center;
  .wscn-http404 {
    text-align: center;
    img {
      object-fit: cover;
      width: 100%;
      // height: 60vw;
    }
  }
}
</style>
