/*
 * @Author: 蒋威
 * @Date: 2022-04-16 10:54:42
 * @LastEditors: 蒋威
 * @LastEditTime: 2022-05-09 17:21:11
 * @Description:栏目管里/菜单相关接口
 */
import request from '@/utils/request'
// 菜单
export function channelTree(params) {
  return request({
    url: `/api/web/channelTree`,
    method: 'get',
    params,
  })
}
// 二级菜单
export function channelById(params) {
  return request({
    url: `/api/web/channelById`,
    method: 'get',
    params,
  })
}
