<!--
 * @Author: 蒋威
 * @Date: 2022-04-07 08:42:40
 * @LastEditors: 蒋威
 * @LastEditTime: 2022-05-27 14:32:29
 * @Description: 
-->
<template>
  <section class="app-main">
    <PageErr v-if="type == '404'" />
    <template v-else>
      <LayoutHeader :website='website' v-show="$route.path != '/404'"  @setType="setType" />
      <div class="layout-main">
        <router-view :key="key" v-if="isRouterAlice" />
      </div>
      <LayoutFooter :website='website' v-show="$route.path != '/404'" class="" />
    </template>
  </section>
</template>

<script>
import LayoutFooter from './components/LayoutFooter/LayoutFooter.vue'
import LayoutHeader from './components/LayoutHeader/LayoutHeader.vue'
import PageErr from '@/components/404.vue'
import { mapGetters } from 'vuex'

import { website_list } from '@/api/homePage/index.js'
export default {
  name: 'Layout',
  provide() {
    return {
      reload: this.reload,
    }
  },
  components: {
    LayoutFooter,
    LayoutHeader,
    PageErr,
  },
  computed: {
    key() {
      return this.$route.path
    },
  },
  mounted() {},
  data() {
    return {
      loading: true,
      isRouterAlice: true,
      type: 0,
      scrollTop: 0,
      //网站配置
      website: {},
    }
  },
  methods: {
    reload() {
      this.isRouterAlice = false
      this.$nextTick(function () {
        document.body.scrollTop = 0
        document.documentElement.scrollTop = 0
        this.isRouterAlice = true
      })
    },
    setType(type) {
      this.type = type
    },
       // 获取网站配置信息
    async getwebsite_list() {
      const res = await website_list()
      if (res.code === this.$httpCode) {
        this.website = res.data
          document.title = this.website.title||'普益云公益管理平台'
      }
    },
  },
  created() {
    
    this.getwebsite_list()
  },
  watch: {
    $route: {
      deep: true,
      immediate: true,
      handler(val) {
        this.type = this.$route.params.type || ''
        this.$nextTick(() => {
          const minHeight =
            document.body.clientHeight -
            document.getElementsByClassName('layout-header')[0].clientHeight -
            document.getElementsByClassName('layout-footer')[0].clientHeight
          document.getElementsByClassName('layout-main')[0]
            ? (document.getElementsByClassName(
                'layout-main'
              )[0].style.minHeight = minHeight + 'px')
            : ''
        })
      },
    },
  },
}
</script>

<style lang="scss" scoped>
.app-main {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  // overflow-x: hidden;
  // background-color: #333;
}
.layout-main {
  background: #ffffff;
  // flex: 1;
  // background: #F5F5F5;
}
.app-main.load {
  background: #ffffff;
  // background: #F5F5F5;
  .layout-footer {
  background: #ffffff;
  // background: #F5F5F5;
  }
}
</style>
