<!--
 * @Author: 蒋威
 * @Date: 2022-04-07 08:31:56
 * @LastEditors: 蒋威
 * @LastEditTime: 2022-06-17 09:19:28
 * @Description: 
-->
<template>
  <footer class="layout-footer">
    <div class="webinfo-container">
      <ul class="webinfo-wrap response">
        <li class="weblogo">
          <img :src="website.bottomLogoId" :alt="website.title" />
        </li>
        <li class="info-box info-telephone">
          <label>联系电话</label>
          <div class="info-text">{{ website.phone }}</div>
        </li>
        <li class="info-box">
          <label>地址</label>
          <div class="info-text">
            {{
              website.provinceName +
                website.cityName +
                website.areaName +
                website.address || ''
            }}
          </div>
        </li>
        <li class="WeChatimg">
          <img :src="website.qrCodeId" alt="" />
        </li>
        <li class="mobileTerminal">
          <img
            class="bottomLogoimg"
            v-if="website.bottomLogoId"
            :src="website.bottomLogoId"
            :alt="website.title"
          />
          <img class="qrCodeimg" :src="website.qrCodeId" alt="" />
        </li>
      </ul>
    </div>
    <div class="foot-bottom">
      <div class="website-box response">
        <div class="website-item">
            <span>
            公安备案号：
            <a class="Jump" href="http://www.beian.gov.cn/" target="_blank">
              {{ website.publicNo }}
            </a>
          </span>
        </div>
        <div class="website-item">
           <span>
            网站备案号：
            <a class="Jump" href="http://beian.miit.gov.cn/" target="_blank">
              {{ website.icpNo }}
            </a>
          </span>
         </div>
        <div class="website-item">
          <span>{{ website.versionInfo }}</span>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import { website_list } from '@/api/homePage/index.js'

export default {
  name: 'LayoutFooter',
  props: {
    // 网站信息
    website: {
      type: Object,
    },
  },
  data() {
    return {}
  },
  methods: {},
  computed: {},
  created() {},
}
</script>
<style lang="scss" scoped>
@import '@/assets/css/fontsize.scss';
.layout-footer {
  .webinfo-container {
    background: #f8f8f8;
    .webinfo-wrap {
      height: 220px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .weblogo {
        width: 200px;
        height: 80px;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .info-box {
        margin-left: 20px;
        label {
          line-height: 16px;
          font-size: 16px;
          font-family: Microsoft YaHei-Regular, Microsoft YaHei;
          font-weight: 400;
          color: #202020;
        }
        .info-text {
          margin-top: 10px;
          font-size: 16px;
          font-family: Microsoft YaHei-Regular, Microsoft YaHei;
          font-weight: 400;
          color: #666666;
          line-height: 18px;
          // height: 36px;
        }
        &.info-telephone {
          min-width: 140px;
        }
      }
      .WeChatimg {
        width: 150px;
        height: 150px;
        flex: 0 0 150px;
        margin-left: 20px;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
  .foot-bottom {
    height: 60px;
    background: #313131;
    .website-box {
      display: flex;
      height: 60px;
      align-items: center;
      justify-content: space-between;
      .website-item {
        // flex: 0 0 33.33%;
        span {
          font-size: 16px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          line-height: 30px;
          color: #fff;
          a {
            font-size: 16px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            line-height: 30px;
            color: #fff;
          }
        }
      }
    }
  }
  .mobileTerminal {
    display: none;
  }
}

@media only screen and (min-width: 941px) and (max-width: 1299px) {
  .layout-footer {
    .webinfo-container {
      background: #f8f8f8;
      .webinfo-wrap {
        height: 210px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .weblogo {
          width: 180px;
          flex: 0 0 180px;
          height: 72px;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .info-box {
          margin-left: 14px;
          label {
            line-height: 16px;
            font-size: 16px;
          }
          .info-text {
            margin-top: 10px;
            font-size: 16px;
            line-height: 18px;
            // height: 36px;
          }
          &.info-telephone {
            min-width: 140px;
          }
        }
        .WeChatimg {
          width: 120px;
          height: 120px;
          flex: 0 0 120px;
          margin-left: 14px;
          img {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
    .foot-bottom {
      height: 60px;
      background: #313131;
      .website-box {
        display: flex;
        height: 60px;
        align-items: center;
        justify-content: space-between;
        .website-item {
          // flex: 0 0 33.33%;
          span {
            font-size: 16px;
            line-height: 30px;
            color: #fff;
            a {
              font-size: 16px;
              line-height: 30px;
            }
          }
        }
      }
    }
    .mobileTerminal {
      display: none;
    }
  }
}
@media only screen and (min-width: 769px) and (max-width: 940px) {
  .layout-footer {
    .webinfo-container {
      background: #f8f8f8;
      .webinfo-wrap {
        height: 180px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .weblogo {
          width: 160px;
          flex: 0 0 160px;
          height: 64px;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .info-box {
          margin-left: 12px;
          label {
            line-height: 16px;
            font-size: 14px;
          }
          .info-text {
            margin-top: 10px;
            font-size: 14px;
            line-height: 18px;
            // height: 36px;
          }
          &.info-telephone {
            min-width: 130px;
          }
        }
        .WeChatimg {
          width: 110px;
          height: 110px;
          flex: 0 0 110px;
          margin-left: 12px;
          img {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
    .foot-bottom {
      height: 60px;
      background: #313131;
      .website-box {
        display: flex;
        height: 60px;
        align-items: center;
        justify-content: space-between;
        .website-item {
          // flex: 0 0 33.33%;
          span {
            font-size: 16px;
            line-height: 30px;
            color: #fff;
            a {
              font-size: 16px;
              line-height: 30px;
            }
          }
        }
      }
    }
    .mobileTerminal {
      display: none;
    }
  }
}
@media only screen and (max-width: 768px) {
  .layout-footer {
    .webinfo-container {
      background: #f8f8f8;
      .webinfo-wrap {
       
        padding: 10px 0;
        height: auto;
        display: block;
        .weblogo {
          display: none;
          width: 100%;
          height: 80px;
          text-align: center;
          img {
            width: 180px;
            height: 72px;
          }
        }
        .info-box {
          margin-left: 12px;
          flex-wrap: wrap;
          margin-top: 10px;
          label {
            line-height: 16px;
            font-size: 14px;
            margin-right: 10px;
          }
          .info-text {
            display: inline-block;
            margin-top: 10px;
            font-size: 14px;
            line-height: 18px;
            margin-top: 0;
            // height: 36px;
          }
          &.info-telephone {
            min-width: 140px;
          }
        }
        .WeChatimg {
          width: 100%;
          height: 100px;
          display: none;
          text-align: center;
          margin: 0;
          margin-top: 10px;
          img {
            width: 100px;
            height: 100%;
          }
        }
      }
    }
    .foot-bottom {
      height: auto;
      background: #313131;
      padding: 10px 0;
      .website-box {
        display: block;
        height: auto;
        align-items: center;
        justify-content: space-between;
        .website-item {
          text-align: center;
          span {
            font-size: 16px;
            line-height: 30px;
            color: #fff;
            a {
              font-size: 16px;
              line-height: 30px;
            }
          }
        }
      }
    }
    
    .mobileTerminal {
      display: flex;
      margin-top: 10px;
      align-items: center;
      padding: 0 12px;
      justify-content: space-between;
      .bottomLogoimg {
        width: 180px;
        height: 72px;
      }
      .qrCodeimg{
        width: 100px;
        height: 100px;
      }
    }
  }
}
</style>
