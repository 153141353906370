/*
 * @Author: 蒋威
 * @Date: 2022-05-08 15:47:33
 * @LastEditors: 蒋威
 * @LastEditTime: 2022-05-25 09:47:43
 * @Description: 菜单相关数据
 */
const state = {
  //二级菜单
  menu: [],
}

const mutations = {
  SET_MENU: (state, menu) => {
    state.menu = menu
  },
}

const actions = {
  setmenu({ commit }, menu) {
    return new Promise((resolve) => {
      commit('SET_MENU', menu)
      resolve(menu)
    })
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
}
