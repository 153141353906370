/*
 * @Author: 蒋威
 * @Date: 2022-04-15 09:34:40
 * @LastEditors: 蒋威
 * @LastEditTime: 2022-05-25 09:47:52
 * @Description:
 */
const getters = {
  menu: (state) => state.menu.menu,
}
export default getters
