<!--
 * @Author: 蒋威
 * @Date: 2022-05-09 10:20:36
 * @LastEditors: 蒋威
 * @LastEditTime: 2022-06-17 09:31:10
 * @Description: 二级菜单
-->
<template>
  <div class="secondmenu-wrap" v-if="menuList.length>0">
    <ul class="second-menu response">
      <li
        class="second-menu-item pointer"
        v-for="(item, index) in menuList"
        :key="index"
        :class="{
          active:
            !!$route.query.cate_id || $route.query.id == 0
              ? $route.query.cate_id - 0 == item.id
              : item.id == menuList[0].id - 0,
        }"
        @click="changeMenu(item, index)"
      >
        <span>
          {{ item.title }}
        </span>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  inject: ['reload'],
  props: {
    menuList: {
      type: Array,
      default: () => {
        return []
      },
    },
  },
  data() {
    return {
      // 选中的索引
      activeIndex: 0,
    }
  },
  methods: {
    changeMenu(item, index) {
      this.activeIndex = index
      if (item.model == 'url' && item.url) {
        window.open(item.url, '_blank')
      } else {
        this.$emit('changeMenu', item)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/fontsize.scss';
.secondmenu-wrap {
  background-color: #f5f5f5;
  .second-menu {
    display: flex;
    align-items: flex-end;
    flex-wrap: wrap;
    .second-menu-item {
      height: 60px;
      min-width: 60px;
      display: flex;
      align-items: center;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      margin-right: 60px;
      span {
        height: 21px;
        font-size: 18px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        line-height: 21px;
        color: #202020;
      }
      &:hover,
      &.active {
        span {
          color: #39957a;
        }
      }
    }
  }
}

@media only screen and (min-width: 769px) and (max-width: 940px) {
  .secondmenu-wrap {
    background-color: #f5f5f5;
    .second-menu {
      display: flex;
      align-items: flex-end;
      flex-wrap: wrap;
      padding: 10px 0 10px  20px;
      .second-menu-item {
        height:  20px;
        min-width: unset;
        display: flex;
        align-items: center;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 400;
        margin-right:  14px;
        span {
          height:  20px;
          font-size: 16px;
          line-height:   20px;
        }
      }
    }
  }
}
@media only screen and (max-width:768px) {
  .secondmenu-wrap {
    margin-top: 10px;
    background-color: #f5f5f5;
    .second-menu {
      display: flex;
      align-items: flex-end;
      flex-wrap: wrap;
      padding: 10px 0 10px  20px;
      .second-menu-item {
        height:  20px;
        min-width: unset;
        display: flex;
        align-items: center;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 400;
        margin-right:  14px;
        span {
          height:  20px;
          font-size: 16px;
          line-height:   20px;
        }
      }
    }
  }
}
</style>