/*
 * @Author: 蒋威
 * @Date: 2022-04-14 16:04:04
 * @LastEditors: 蒋威
 * @LastEditTime: 2022-04-14 16:28:14
 * @Description: 
 */
/**
 * @description 4个子配置，vue/cli配置|通用配置|主题配置|网络配置导出
 *              config中的部分配置由vue.config.js读取，本质是node，故不可使用window等浏览器对象
 */
const network = require('./net.config')
module.exports = {
  ...network,
}
