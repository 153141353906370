<!--
 * @Author: 蒋威
 * @Date: 2022-04-07 15:52:50
 * @LastEditors: 蒋威
 * @LastEditTime: 2022-05-27 14:34:36
 * @Description: 
-->
<template>
  <div id="app">
    <div id="Layout" v-show="!lowVersion">
      <Layout />
      <!-- v-if="isRouterAlice"  -->
    </div>
    <!-- 低版本ie 9 8 7  -->
    <div id="IEVersion" v-show="lowVersion">
      <div class="IEVersion-warp">
        <img
          class="pic-404__parent"
          src="@/assets/404_images/IEVersion.jpg"
          alt="404"
        />
        <div class="IEVersion-text">
          你正在使用的浏览器版本过低，请使用其他浏览器访问
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Layout from '@/layouts'
import { IEVersion } from '@/utils/index.js'
export default {
  name: 'app',
  // provide() {
  //   return {
  //     reload: this.reload,
  //   }
  // },
  components: {
    Layout,
  },
  data() {
    return {
      // isRouterAlice: true,
      //  低版本
      lowVersion: [6, 7, 8, 9].includes(IEVersion()),
    }
  },
  methods: {
    reload() {
      this.isRouterAlice = false
      this.$nextTick(function () {
        document.body.scrollTop = 0
        document.documentElement.scrollTop = 0
        this.isRouterAlice = true
      })
    },
    /**
     * @Auth: 蒋威
     * @Date: 2022-04-27 08:47:02
     * @Description: 判断ie浏览器及版本
     */
    IEVersion() {
      switch (IEVersion()) {
        case 9:
        case 8:
        case 7:
        case 7:
        case 6:
          this.$nextTick(() => {
            document.getElementById('app').className = 'ie9box'
            document.getElementById('Layout').style.display = 'none'
            document.getElementById('IEVersion').style.display = 'block'
            // alert('你正在使用的浏览器版本过低，请使用其他浏览器访问')
          })
          break
        case 11:
          console.log('11', '浏览器')
          break
        case 'edge':
          console.log('edge', '浏览器')
          break
        case -1:
          console.log('-1', '浏览器')
          break
        default:
          break
      }
    },
  },
  created() {
    this.IEVersion()
  },
}
</script>

<style scoped lang="scss">
#app {
  height: 100%;
  width: 100%;
}
// #Layout {
//   height: 100%;
//   width: 100%;
// }
#IEVersion {
  display: none;
  position: relative;
  width: 500px;
  left: 50%;
  top: 30%;
  margin-left: -250px;
  text-align: center;
  .IEVersion-text {
    margin-top: 10px;
  }
}
</style>