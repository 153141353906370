<!--
 * @Author: 蒋威
 * @Date: 2022-04-16 17:29:15
 * @LastEditors: 蒋威
 * @LastEditTime: 2022-05-08 10:20:48
 * @Description: 分页组件
-->
<template>
  <el-pagination
    background
    v-show="total > 0"
    :page-size="pageSize"
    layout="prev,pager,next"
    :total="total"
    :pager-count="pagerCount"
    :current-page="currentPage"
    @current-change="currentChange"
  ></el-pagination>
</template>

<script>
export default {
  props: {
    total: {
      type: Number,
      default: 0,
    },
    pageSize: {
      type: Number,
      default: 6,
    },
    currentPage: {
      type: Number,
      default: 1,
    },
    pagerCount: {
      type: Number,
      default: 9,
    },
  },
  methods: {
    // 当前页
    currentChange(val) {
      this.$emit('currentChange', val)
    },
  },
}
</script>

<style scoped lang="scss">
</style>