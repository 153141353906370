/*
 * @Author: 蒋威
 * @Date: 2022-04-07 15:52:50
 * @LastEditors: 蒋威
 * @LastEditTime: 2022-06-07 10:49:35
 * @Description:
 */
import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('@/views/home/index.vue'), //   columnName: '详情',
    meta: {
      title: '普益云公益管理平台',
    },
  },
  {
    path: '/news',
    name: 'news',
    component: () => import('@/views/news/index.vue'), 
    meta: {
      title: '普益云公益管理平台',
    },
  },
  {
    path: '/newsInfo',
    name: 'newsInfo',
    component: () => import('@/views/newsInfo/index.vue'), 
    meta: {
      title: '普益云公益管理平台',
    },
  },
  {
    path: '/404',
    name: '404',
    menuUrl: '404',
    columnName: '404',
    meta: {
      title: '404',
    },
    component: () => import('@/views/errorPage/404.vue'),
  },
  {
    path: '*',
    redirect: '/',
    meta: {
      title: '普益云公益管理平台',
    },
  },
]
export const constantRoutes = [
  {
    path: '*',
    redirect: '/',
    meta: {
      title: '普益云公益管理平台',
    },
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior: () => ({ y: 0 }),
})
const VueRouterPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(to) {
  return VueRouterPush.call(this, to).catch((err) => err)
}
export default router
